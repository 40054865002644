import React, { Component } from 'react'
import './App.scss'
import { splitGraphemes } from 'split-graphemes'
import { CopiableField } from './CopiableField'

type Props = {}
interface State {
  text: string,
  cursorPositionStatus: {
    lineOfStart: number,
    charOfStart: number,
    lineOfEnd: number,
    charOfEnd: number,
    sameOfStartToEnd: boolean
  },
  showLicenses: boolean,
  showPrivacyPolicy: boolean,
  numOfChars: number,
  numOfCharsWoSpaces: number,
  numOfWords: number,
  numOfLines: number
}

export class App extends Component<Props, State> {
  state: State = {
    cursorPositionStatus: {
      charOfEnd: 0,
      charOfStart: 0,
      lineOfEnd: 0,
      lineOfStart: 0,
      sameOfStartToEnd: true
    },
    numOfChars: 0,
    numOfCharsWoSpaces: 0,
    numOfLines: 0,
    numOfWords: 0,
    showLicenses: false,
    showPrivacyPolicy: false,
    text: ''
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.currentTarget.value
    const numOfChars = splitGraphemes(text).length
    const numOfCharsWoSpaces = splitGraphemes(text.replace(/\s/gu, '')).length
    const numOfWords = text
      ? text.trim().split(/\s+/u).length
      : 0
    const numOfLines = text
      ? (text.match(/\n/ug) || []).length + 1
      : 0
    this.setState({
      numOfChars,
      numOfCharsWoSpaces,
      numOfLines,
      numOfWords,
      text
    })
  }

  onToggleLicenses = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ showLicenses: !this.state.showLicenses })
  }

  onTogglePrivacyPolicy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ showPrivacyPolicy: !this.state.showPrivacyPolicy })
  }

  onFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputElement = e.currentTarget
    inputElement.setSelectionRange(
      0,
      inputElement.value.length
    )
  }

  onChangeSelection = (e: React.MouseEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLTextAreaElement> | React.ClipboardEvent<HTMLTextAreaElement>) => {
    const textareaElement = e.currentTarget
    const { value, selectionStart, selectionEnd } = textareaElement

    const preStartTextSplits = value.slice(0, selectionStart).split('\n')
    const lineOfStart = preStartTextSplits.length
    const charOfStart = splitGraphemes(preStartTextSplits[preStartTextSplits.length - 1]).length

    if (selectionStart === selectionEnd) {
      this.setState({
        cursorPositionStatus: {
          charOfEnd: 0,
          charOfStart,
          lineOfEnd: 0,
          lineOfStart,
          sameOfStartToEnd: true
        }
      })
    } else {
      const preEndTextSplits = value.slice(0, selectionEnd).split('\n')
      const lineOfEnd = preEndTextSplits.length
      const charOfEnd = splitGraphemes(preEndTextSplits[preEndTextSplits.length - 1]).length
      this.setState({
        cursorPositionStatus: {
          charOfEnd,
          charOfStart,
          lineOfEnd,
          lineOfStart,
          sameOfStartToEnd: false
        }
      })
    }
  }

  render () {
    const {
      cursorPositionStatus,
      text,
      numOfChars,
      numOfCharsWoSpaces,
      numOfWords,
      numOfLines
    } = this.state

    return (
      <div className='app'>
        <div className='background-container' />
        <div className='card'>
          <header className=''>
            Online Word Counter
          </header>
          <section className='main'>
            <textarea onChange={this.onChange} onSelect={this.onChangeSelection} value={text} wrap='on' autoFocus />
            <table className='fields'>
              <tbody>
                <tr>
                  <th>Current at</th>
                  <td>
                    <CopiableField
                      title={'Copy the number of lines'}
                      value={
                        cursorPositionStatus.sameOfStartToEnd
                          ? `L${cursorPositionStatus.lineOfStart}:${cursorPositionStatus.charOfStart}`
                          : `L${cursorPositionStatus.lineOfStart}:${cursorPositionStatus.charOfStart} ~ L${cursorPositionStatus.lineOfEnd}:${cursorPositionStatus.charOfEnd}`
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th># of characters</th>
                  <td>
                    <CopiableField title={'Copy the number of characters'} value={numOfChars.toString()} />
                  </td>
                </tr>
                <tr>
                  <th># of chars(no spaces)</th>
                  <td>
                    <CopiableField title={'Copy the number of characters without spaces'} value={numOfCharsWoSpaces.toString()} />
                  </td>
                </tr>
                <tr>
                  <th># of words</th>
                  <td>
                    <CopiableField title={'Copy the number of words'} value={numOfWords.toString()} />
                  </td>
                </tr>
                <tr>
                  <th># of lines</th>
                  <td>
                    <CopiableField title={'Copy the number of lines'} value={numOfLines.toString()} />
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <footer>
          <div className='licenses'>
            <a className='show-licenses' href='/licenses.html' target='_blank' rel='noopener noreferrer'>
              Licenses
            </a>
            &nbsp;|&nbsp;
            <a className='show-licenses' href='/privacy.html' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
          </div>
          <div className='copyright' ><a href='https://twitter.com/akiroom' target='_blank' rel='noopener noreferrer'>@akiroom</a></div>
        </footer>
      </div>
    )
  }
}
