import React, { Component } from 'react'

interface Props {
  title: string,
  value: string
}

interface State {
  showCopiedMessage: boolean
}

export class CopiableField extends Component<Props, State> {
  state: State = {
    showCopiedMessage: false
  }

  onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.select()
  }

  onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const inputElement: HTMLInputElement | null = e.currentTarget.previousElementSibling as HTMLInputElement
    if (!inputElement) {
      return
    }
    inputElement.select()
    document.execCommand('copy')
    this.setState({ showCopiedMessage: true })

    setTimeout(() => {
      this.setState({ showCopiedMessage: false })
    }, 2000)
  }

  render () {
    const { title, value } = this.props
    const { showCopiedMessage } = this.state
    return (
      <div className='copiable-field'>
        <input type='text' value={value} onFocus={this.onFocus} readOnly />
        <button className='copy' onClick={this.onClick} data-value={value} title={title}>
          <img src='/clipboard@2x.png' alt='' />
        </button>
        <div className={`copied-message ${showCopiedMessage ? 'visible' : ''}`}>
          Copied: {value}
        </div>
      </div>
    )
  }
}
